import * as React from "react";
import PageComponent from "src/components/pages/the-process";

const Page = ({ location }) => {
  const copy = {
    list: [
      `הצעד הראשון ברכישת ביטוח חיים הוא להחליט בנוגע לכמות הכיסוי הביטוחי שברצונך לרכוש. אם אינך בטוח, הדרך הטובה ביותר לברר זאת היא לקבוע פגישה לשיחת טלפון או לשיחת וידאו דרך המחשב עם סוכן ביטוח מוסמך. שיחת וידאו היא הדרך קלה ביותר וניתן להתחבר אליה דרך כל מחשב שמחובר לרשת האינטרנט. אנו כמובן נסייע בעריכת הפגישה.`,
      `הצעד הבא הוא לבדוק לאיזו קטגוריית חיתום המבוטח שייך, מה שיקבע את מחיר הפרמיה  עבור רוכשים פוטנציאלים שהינם צעירים ובריאים או בעבור כמויות קטנות של כיסוי ביטוחי ניתן להעריך זאת ע”י סקירה קצרה של ההיסטוריה הרפואית. עבור מבוטחים פוטנציאלים רבים הדרך הטובה ביותר לעשות “חיתום לא פורמלי”  (הערכת מחיר לא רשמית) היא ע”י סקירה של התיק הרפואי. בהסכמתכם אנו נזמין ונקבל את התיק הרפואי מהרופאים. האינפורמציה הרלוונטית מועברת ל”חתם” של החברה המבטחת לצורך הערכת “חיתום לא פורמלי”. לאנשים שמבקרים אצל הרופא באופן שוטף  הערכה זו נוטה להיות מדוייקת במעל ל 90% מהמקרים.`,
      `לאחר שקטגוריית החיתום והמחיר המשוערך התבררו, נקבעת פגישה נוספת לעבור על התוצאה ולראות כיצד מתרגמים אותה למספר אפשרויות ומחירים.  אם סקירה זו תסתיים בהתעניינות רצינית לקניית ביטוח חיים יש להתקדם לשלב “החיתום הפורמלי”.`,
      `החיתום הפורמלי (הערכת מחיר רשמית) מורכב מהשלמה וחתימה על מסמכי הבקשה ומבדיקה רפואית. ניתן להשלים את החיתום הפורמלי בארה”ב, בשלב מסויים יש צורך לצאת מחוץ לישראל בכדי להשלים את הליך המכירה. אצל חלק מהחברות ניתן לעשות את הבדיקה הרפואית בישראל, אך לעולם לא ניתן לחתום על המסמכים בישראל, חלק מהחברות מאפשרות לחתום על המסמכים גם בשוויץ, בריטניה או גרמניה. לאחר שנקבע מקום החיתום אנו נעשה את הסידורים לצורך בדיקת הרופא, המילוי והחתימה על מסמכיהבקשה.בארה”ב נעשה זאת במקום ובזמן שתבחרו. בדיקת הרופא לוקחת בדר”כ פחות משעה ומבוצעת בדר”כ ע”יאחות. מילוי המסמכים והחתימה על הבקשה נעשים גם כן בפחות משעה.אצל חלק מהחברות המבטחות ייתכן כי יידרש מכם לפתוח קרן נאמנות בסיסית בארה”ב בכדי שתוכלו לרכוש את הפוליסה. דבר זה יוכל לעזור ולהקל את הלוגיסטיקה של תהליך החיתום. אם יימצא לנכון לעשות קרן נאמנות, אנו יכולים לסייע לך למצוא דרך חסכונית מאוד להשיג מטרה זו.`,
      `לאחר שהחיתום הפורמלי הושלם והוגשו מסמכי הבקשה אנו נחכה שהחברה תיתן הצעה פורמלית של עלות  הביטוח , מה שיאפשר לנו לשקול את המחירים האמיתיים. בזמן זה עדיין אפשרי לשנות את סכומי הכיסוי הביטוחי (נתון לחיתום) וכן את סוג הכיסוי שיינתן.`,
      `ברגע שהפוליסה הונפקה והפרמיה הראשונית שולמה, הכיסוי בתוקף מלא.`,
    ],
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="תהליך רכישת ביטוח חיים עבור תושב ישראל"
    />
  );
};

export default Page;
